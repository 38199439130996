.transaction-form {
    margin-bottom: 16px;
}

.transaction-form-row {
    padding: 16px 0 0;
    margin: 0;
    border-bottom: 1px solid rgba(19, 19, 19, .2);
}

.total-selling-amount {
    color: gray;
    font-size: 14px;
    }

@media screen and (max-width: 992px) {
    .total-amount,
    .total-selling-amount {
        padding: 0 0 16px;
    }
}