.fc {
    margin: 0 60px;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;
}

.fc-today-button {
    display: none !important;
}

.fc .fc-button-primary {
    background-color: transparent;
    border: none;
    color: rgba(0, 0, 0, .5);
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:active {
    background-color: transparent !important;
    border: none;
    color: rgba(0, 0, 0, .75) !important;
}

.fc-toolbar-title {
    font-size: 24px !important;
    text-transform: capitalize;
}