.textarea {
    width: 100%;
    background-color: #EEF3F5;
    border: none;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    resize: none;
}

.textarea--error {
    background: rgba(255, 63, 46, 0.12);
    margin-bottom: 2px;
}

.textarea-floating-label {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    color: #131313;
    opacity: 0.5;
}

.textarea-floating-label.big {
    left: 30px;
    top: 15px;
}

.textarea-floating-label.small {
    left: 30px;
    top: 2px;
    font-size: 10px;
}