.attachment-item {
  padding: 16px 0 8px;
  margin: 0;
  border-bottom: 1px solid rgba(19, 19, 19, .2);
}

.attachment-item__url {
  font-weight: bold;
  text-align: left;
  font-size: 15px;
}

.attachment-item__text {
  color: rgba(0, 0, 0, .5) !important;
  font-size: 15px;
}

.attachment-item__delete svg {
  color: #FF9D85;
  font-size: 34px;
  cursor: pointer;
  position: relative;
  top: -6px;
}

.add-attachment {
    margin: 16px 0 8px;
}

.add-attachment .lds-ellipsis {
  height: 20px;
}

.new-attachment-name {
  color: rgba(0, 0, 0, .5) !important;
  margin: 25px 16px 0 4px;
}