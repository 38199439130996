.dropdown {

}

.dropdown__button {
  background: #EEF3F5;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
}

.dropdown__menu {
  position: absolute;
  right: 16px;
  z-index: 9999;
  background: white;
  border: 2px solid black;
  border-radius: 12px;
  padding: 8px 16px;
  margin-top: 8px;
  min-width: 200px;
  width: 100%;
}

.dropdown__item {
  background: white;
  width: 100%;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  padding: 6px 0;
  text-align: start;
}
