.projects-list {
    padding-top: 16px;
}

.projects-list tr {
    position: relative;
}

.projects-list tr {
    font-weight: normal !important;
    background: #EEF3F5 !important;
}

.projects-list tr.main-project {
    font-weight: bold !important;
    background: #ffffff !important;
}

.projects-list .project-name {
    text-align: left !important;
    display: inline-block;
}

.projects-list .tree-node {
    display: inline-block;
    border-bottom: 2px solid #CCCCCC;
}
