.select-wrapper {
    position: relative;
}

.select-floating-label {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    color: #131313;
    opacity: 0.5;
    /*padding-left: 16px !important;*/
}

.select-floating-label.big {
    left: 30px !important;
    top: 15px;
}

.select-floating-label.small {
    left: 30px;
    top: 2px;
    font-size: 10px;
}