.form {
    background: #FFFFFF;
    border-radius: 24px;
    padding: 36px 20px;
}

.form--header {
    font-size: 24px;
    text-align: left;
    margin: 4px 0 24px;
}