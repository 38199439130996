.input-wrapper {
    position: relative;
}

.input {
    width: 100%;
    background-color: #EEF3F5;
    border-radius: 12px;
    height: 56px;
    padding: 8px 16px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.input--error {
    background: rgba(255, 63, 46, 0.12);
    margin-bottom: 2px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.floating-label {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    color: #131313;
    opacity: 0.5;
}

.floating-label.big {
    left: 16px;
    top: 15px;
}

.floating-label.small {
    left: 12px;
    top: 2px;
    font-size: 10px;
}

/*@media screen and (max-width: 992px) {*/
/*    .floating-label {*/
/*        font-size: 12px;*/
/*    }*/
/*}*/