.aside {
    background: #EDF4F7;
    min-height: calc(100vh - 80px);
    height: 100%;
    border-top-left-radius: 20px;
    padding: 16px 24px;
    text-align: left;
    border-right: 2px solid #fff;
}

.aside-header {
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.aside-header--manager {
    color: rgba(0, 0, 0, .5) !important;
    font-size: 15px;
}

.aside-header > a {
    display: block;
    color: #000;
    font-size: 16px;
    margin-bottom: 8px;
}

.aside-row {
    padding-left: 8px;
}

.aside-row a,
.aside-row span {
    font-size: 13px;
    color: rgba(0, 0, 0, .5) !important;
}

.aside .aside-row-balance {
    display: inline-block;
    text-align: right;
    float: right;
    padding-right: 16px;
}

.aside .aside-row-badge {
    display: inline-block;
    text-align: right;
    float: right;
    background-color: rgba(0, 0, 0, .75) !important;
    border-radius: 50px;
    margin-right: 8px;
    padding: 0 8px;
    color: white;
    font-weight: bold;
}