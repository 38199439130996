.project {
    padding: 16px 0;
}

.project-header {
    text-align: left;
}

.project-tabs-menu {
    margin: 8px 0 16px;
}

.project-tabs-menu a {
    display: inline-block;
    padding: 8px 16px;
    color: #131313;
    opacity: 0.4;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.project-tabs-menu a.active {
    color: #fff;
    opacity: 1;
    background: #181D1F;
    border-radius: 20px;
}