.header {
    height: 80px;
    background: #181D1F;
    padding-top: 20px;
    padding-bottom: 20px;
}

.header--logo {
    color: #fff;
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: bold;
    text-align: left;
    display: block;
    padding-left: 8px;
}

.header--logout {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 24px;
    padding: 8px 16px;
    color: #fff;
    border: none;
    font-weight: bold;
}

@media screen and (max-width: 992px) {
    .header--logo {
        font-size: 24px;
        padding-left: 0;
    }
}