.project-add {
    min-height: calc(100vh - 200px);
    padding-top: 20px;
}

.project-add form {
    margin-top: 10vh;
}

@media screen and (max-width: 992px) {
    .project-add form {
        margin-top: 2vh;
    }
}