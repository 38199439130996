.modal {
    outline: none;
    width: 35rem;
    max-width: 90%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 100;
    min-height: 10rem;
    border-radius: 20px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

}