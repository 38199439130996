.main {
    background-color: #181D1F;
    min-height: calc(100vh - 80px);
}

.content {
    background-color: #FFFFFF;
    min-height: calc(100vh - 80px);
    margin-left: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

@media screen and (max-width: 992px) {
    .content {
        min-height: calc(100vh - 80px);
        margin-left: 4px;
        margin-right: 4px;
    }
}