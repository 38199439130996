.table-wrapper {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.table {
    width: 100%;
    padding: 0 16px;
    min-width: 500px;
}

.table td {
    padding: 26px 8px;
    text-align: left;
    font-size: 14px;
}

.table tbody td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.table tbody tr:hover {
    background: rgba(237, 244, 247, 0.5);
    cursor: pointer;
}

.table tr td {
    text-align: center;
}

.table tr td:first-child {
    text-align: left;
}

.table tr td:last-child {
    text-align: right;
}

.table thead td {
    font-weight: bold;
    color: rgba(0, 0, 0, .5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    /*border-top: 1px solid rgba(0, 0, 0, 0.2);*/
    padding: 16px 8px;
}

.table-positive-value {
    color: #00B28E;
    font-weight: bold;
}

.table-negative-value {
    color: #FF6333;
    font-weight: bold;
}