.button {
    background: linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%);
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
}

.button.success {
    background: linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%);
}

.button.danger {
    background: linear-gradient(88.88deg, #FF9D85 0%, #FFD585 100%);
}

.button.grey {
    background: #EEF3F5;
}

.button.small {
    height: 40px;
}