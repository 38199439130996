.breadcrumbs {
  text-align: left;
}

.breadcrumbs  h4 {
    margin: 0;
    font-weight: normal;
    color: #131313;
    opacity: 0.5;
    font-size: 14px;
}

.breadcrumbs a {
    color: #131313;
    margin: 0 8px;
}

.breadcrumbs span {
    font-size: 16px;
}