.confirm-badge,
.not-confirm-badge {
  margin: 0 8px;
  color: white;
  padding: 4px 8px;
  border-radius: 50%;
}

.confirm-badge {
  background-color: #7EF5AD;
}

.not-confirm-badge {
  background-color: #FF9D85;
}
